

























































import Vue from 'vue'
import { LOGIN } from '@/store/actions'
import LoginModel from '@/models/LoginModel'

export default Vue.extend({
  name: 'Login',
  components: {
    // HelloWorld
  },
  data: () => ({
    username: '',
    password: '',
    options: {
      isLoggingIn: true
      // shouldStayLoggedIn: true
    }
  }),
  methods: {
    login () {
      if (this.username !== '' && this.password) {
        const model = new LoginModel()
        model.username = this.username
        model.password = this.password

        this.$store.dispatch(LOGIN, model).then(() => {
          // router.push('/')
        }).catch(() => {
          alert('Error Logging in!')
        })
      } else {
        alert('User and Password hast to be set')
      }
    }
  }
})
